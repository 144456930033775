export const data = [
  {
    "id": 1,
    "Name Vorname": "Alb Daniel",
    "Firma": "Censhare (Schweiz) AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "https://www.linkedin.com/in/albdaniel/?originalSubdomain=ch",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 2,
    "Name Vorname": "Altenburger Roger",
    "Firma": "Otto Fischer AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "https://www.linkedin.com/in/roger-altenburger-476b5653/detail/photo/",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 3,
    "Name Vorname": "Angehrn Thomas",
    "Firma": "rqmicro AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 4,
    "Name Vorname": "Aresu Fabio",
    "Firma": "Markenkern AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 5,
    "Name Vorname": "Arnold Edith",
    "Firma": "Luzerner Zeitung",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 6,
    "Name Vorname": "Bachmann Roger",
    "Firma": "CMT AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "1",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 7,
    "Name Vorname": "Bandorf Anja",
    "Firma": "BNP Landschaftsarchitekten GmbH",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 8,
    "Name Vorname": "Bandy Christian",
    "Firma": "Schweizerische Bundesbahnen SBB AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 9,
    "Name Vorname": "Bayer Sebastian",
    "Firma": "dm-drogerie markt GmbH + Co. KG",
    "Sex": "m",
    "Nationalität": "DE",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 10,
    "Name Vorname": "Belger Belinda",
    "Firma": "Migros-Genossenschafts-Bund",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 11,
    "Name Vorname": "Bellone Veronika",
    "Firma": "Bellone Franchise Consulting GmbH",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 12,
    "Name Vorname": "Beringer Barbara",
    "Firma": "Geschäftsstelle sozialinfo.ch",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 13,
    "Name Vorname": "Bernhard Markus",
    "Firma": "Sparda-Bank Baden-Württemberg eG",
    "Sex": "m",
    "Nationalität": "DE",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 14,
    "Name Vorname": "Bertagnolli Martin",
    "Firma": "IDM Südtirol – Alto Adige",
    "Sex": "m",
    "Nationalität": "IT",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 15,
    "Name Vorname": "Bieber Martin",
    "Firma": "Stiftung Wisli",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 16,
    "Name Vorname": "Bittmann Franz",
    "Firma": "NAMUK Kids outdoor Clothing",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 17,
    "Name Vorname": "Boetsch Tina",
    "Firma": "Lindt Chocolate Competence Foundation",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 18,
    "Name Vorname": "Bollag-Rothschild Anette",
    "Firma": "Bollag-Guggenheim AG Bollag-Guggenheim Fashion Group",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 19,
    "Name Vorname": "Borer Marco",
    "Firma": "UBS Switzerland AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 20,
    "Name Vorname": "Bosshart David",
    "Firma": "GDI Gottlieb Duttweiler Institute",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 21,
    "Name Vorname": "Breit Stefan",
    "Firma": "GDI Gottlieb Duttweiler Institute",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 22,
    "Name Vorname": "Breitschmid Yves",
    "Firma": "Schneeberger AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 23,
    "Name Vorname": "Brodscholl Kuno",
    "Firma": "Redguard AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 24,
    "Name Vorname": "Brown Karen J.",
    "Firma": "SPACE to RENEW AG",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 25,
    "Name Vorname": "Bucher Alexander Sascha",
    "Firma": "IV-Stelle Basel-Stadt",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 26,
    "Name Vorname": "Cadalbert Sabina",
    "Firma": "Migros-Genossenschafts-Bund",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 27,
    "Name Vorname": "Camenzind Leo",
    "Firma": "SUVA Schweiz",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 28,
    "Name Vorname": "Cavigelli Christina",
    "Firma": "Sinnesdüfte AG",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 29,
    "Name Vorname": "Cerar Caroline",
    "Firma": "Management Counterparts – Perveno GmbH",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 30,
    "Name Vorname": "Christen Hans-Ruedi",
    "Firma": "Chocolat Frey AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 31,
    "Name Vorname": "Clavadetscher Rageth",
    "Firma": "Liegenschaften-Betrieb AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 32,
    "Name Vorname": "Conka Susan",
    "Firma": "SAQ-QUALICON AG",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 33,
    "Name Vorname": "Daeppen Dominic",
    "Firma": "Schweizer Radio und Fernsehen SRF",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 34,
    "Name Vorname": "Daske Tom",
    "Firma": "Die Botschaft Communications GmbH",
    "Sex": "m",
    "Nationalität": "DE",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 35,
    "Name Vorname": "de Wolf Laura",
    "Firma": "GDI Gottlieb Duttweiler Institute",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 36,
    "Name Vorname": "Decker Andreas",
    "Firma": "F. Hoﬀmann-La Roche AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 37,
    "Name Vorname": "Deigendesch Thomas",
    "Firma": "Jung von Matt/brand identity",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 38,
    "Name Vorname": "Deutschmann Theo",
    "Firma": "Kompetenzzentrum für Lebensqualität Schönbühl",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 39,
    "Name Vorname": "Dietsche Britta",
    "Firma": "Dietsche",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 40,
    "Name Vorname": "Dotti Ute",
    "Firma": "Lindt Chocolate Competence Foundation",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 41,
    "Name Vorname": "Durrer Konrad",
    "Firma": "CSS Versicherung",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 42,
    "Name Vorname": "Eberle Peter",
    "Firma": "Atentis AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 43,
    "Name Vorname": "Egger Philipp",
    "Firma": "Energieagentur St. Gallen GmbH",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 44,
    "Name Vorname": "Egli Alain",
    "Firma": "GDI Gottlieb Duttweiler Institute",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 45,
    "Name Vorname": "Emch Hansres",
    "Firma": "Censhare (Schweiz) AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 47,
    "Name Vorname": "Erdmann Lars",
    "Firma": "Q-Perior AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 48,
    "Name Vorname": "Fischer Daniel",
    "Firma": "Genossenschaft Migros Aare",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 49,
    "Name Vorname": "Frei Benjamin",
    "Firma": "freicom ag",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 50,
    "Name Vorname": "Freitag Alfred",
    "Firma": "Belimo Automation AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 51,
    "Name Vorname": "Frick Karin",
    "Firma": "GDI Gottlieb Duttweiler Institute",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 52,
    "Name Vorname": "Furtwaengler Arnold",
    "Firma": "Wander AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "11.Jan.63",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 53,
    "Name Vorname": "Gebhardt Birgit",
    "Firma": "Birgit Gebhardt - Trendexpertin",
    "Sex": "f",
    "Nationalität": "DE",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 54,
    "Name Vorname": "Gehrig Melania",
    "Firma": "RIBAG Licht AG",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 55,
    "Name Vorname": "Geiser Roman",
    "Firma": "Farner Consulting AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 56,
    "Name Vorname": "Gemperle Silvia",
    "Firma": "Energieagentur St. Gallen GmbH",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 57,
    "Name Vorname": "Geson Rita",
    "Firma": "IDM Südtirol – Alto Adige",
    "Sex": "f",
    "Nationalität": "IT",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 58,
    "Name Vorname": "Gleitz Markus",
    "Firma": "Audi AG",
    "Sex": "m",
    "Nationalität": "DE",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 59,
    "Name Vorname": "Glöckner Roland",
    "Firma": "Opium eﬀect GmbH",
    "Sex": "m",
    "Nationalität": "DE",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 60,
    "Name Vorname": "Gloor Silvan",
    "Firma": "F. Hoﬀmann-La Roche AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 61,
    "Name Vorname": "Göbbel Tobias",
    "Firma": "Roland Berger GmbH",
    "Sex": "m",
    "Nationalität": "DE",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 62,
    "Name Vorname": "Gottschall Nicole",
    "Firma": "SPACE to RENEW AG",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 63,
    "Name Vorname": "Grand Jean-Marc",
    "Firma": "Schweizerische Gesellschaft für Marketing",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 64,
    "Name Vorname": "Gross-Gonzalez Patricia",
    "Firma": "Swisscom (Schweiz) AG",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 65,
    "Name Vorname": "Grossmann Kaspar",
    "Firma": "Schweizerische Bundesbahnen SBB AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 66,
    "Name Vorname": "Grubenmann Urs",
    "Firma": "Suva",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 67,
    "Name Vorname": "Guhl Elke",
    "Firma": "Electrolux AG",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 68,
    "Name Vorname": "Guyenette Carl",
    "Firma": "",
    "Sex": "m",
    "Nationalität": "GB",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 69,
    "Name Vorname": "Häfliger Beat",
    "Firma": "SAQ-QUALICON AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 70,
    "Name Vorname": "Hahn Wolfgang",
    "Firma": "ECG Energie Consulting GmbH",
    "Sex": "m",
    "Nationalität": "DE",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 71,
    "Name Vorname": "Hammer Esther",
    "Firma": "Hapimag AG",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 72,
    "Name Vorname": "Hanke Gerd",
    "Firma": "Deutscher Fachverlag GmbH",
    "Sex": "m",
    "Nationalität": "DE",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 73,
    "Name Vorname": "Harringer Gerald",
    "Firma": "Die Fabrikanten",
    "Sex": "m",
    "Nationalität": "AT",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 74,
    "Name Vorname": "Heimueller Sandra",
    "Firma": "Cognizant",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 75,
    "Name Vorname": "Heinrici Christian",
    "Firma": "Deutscher Fachverlag GmbH",
    "Sex": "m",
    "Nationalität": "DE",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 76,
    "Name Vorname": "Hensen Vanessa",
    "Firma": "Schweizerische Bundesbahnen SBB AG",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 77,
    "Name Vorname": "Herzog Peter",
    "Firma": "GDI Gottlieb Duttweiler Institute",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 78,
    "Name Vorname": "Hirsbrunner Roman",
    "Firma": "Jung von Matt/brand identity",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 79,
    "Name Vorname": "Hofer Karin",
    "Firma": "Gesundheitsamt Graubünden",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 80,
    "Name Vorname": "Holzheu Elena",
    "Firma": "Zühlke Engineering AG",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 81,
    "Name Vorname": "Hotz Christof",
    "Firma": "APG|SGA AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 82,
    "Name Vorname": "Hübner Florence",
    "Firma": "Swiss Economic Forum AG",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 83,
    "Name Vorname": "Inderbitzin Roberto",
    "Firma": "Reframe",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 84,
    "Name Vorname": "Jockwer Axel",
    "Firma": "Bottimmo AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 85,
    "Name Vorname": "Jordi Christoph",
    "Firma": "DoDiﬀerent GmbH",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 86,
    "Name Vorname": "Kälin Daniel",
    "Firma": "Universitätsspital Zürich",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 87,
    "Name Vorname": "Käppeli Robert",
    "Firma": "Post CH AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 88,
    "Name Vorname": "Karlaganis Andreas",
    "Firma": "Schauspielhaus Zürich AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 89,
    "Name Vorname": "Karsupke Mathias",
    "Firma": "Paycoach AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 90,
    "Name Vorname": "Kazis Julia",
    "Firma": "Schule für Gestaltung Basel | SfG Basel",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 91,
    "Name Vorname": "Kiefer Heike",
    "Firma": "Schweizerische Bundesbahnen SBB AG",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 92,
    "Name Vorname": "Klein Martin",
    "Firma": "trainconsulting",
    "Sex": "m",
    "Nationalität": "AT",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 93,
    "Name Vorname": "Knüsel Philippe",
    "Firma": "Chocolat Frey AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 94,
    "Name Vorname": "Koch Ann-Kristin",
    "Firma": "Swisscom (Schweiz) AG",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 95,
    "Name Vorname": "Köhler Jan",
    "Firma": "Die Botschaft Communications GmbH",
    "Sex": "m",
    "Nationalität": "DE",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 96,
    "Name Vorname": "Kölle Angelika",
    "Firma": "Angelika Kölle Consulting und Training",
    "Sex": "f",
    "Nationalität": "DE",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 97,
    "Name Vorname": "Kölle Katharina",
    "Firma": "Angelika Kölle Consulting und Training",
    "Sex": "f",
    "Nationalität": "DE",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 98,
    "Name Vorname": "Kölle Viktoria ",
    "Firma": "Angelika Kölle Consulting und Training",
    "Sex": "f",
    "Nationalität": "DE",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 99,
    "Name Vorname": "Koller Markus ",
    "Firma": "Universitätsspital Zürich",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 100,
    "Name Vorname": "Koller Roman ",
    "Firma": "koller pr & eventmanagement",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 101,
    "Name Vorname": "Kopp Stefan",
    "Firma": " Lidl Schweiz DL AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 102,
    "Name Vorname": "Krummenacher Jörg",
    "Firma": "KOMMPAKT AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 103,
    "Name Vorname": "Kufus Inge",
    "Firma": "Rolf Kufus Zahnärzte AG",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 104,
    "Name Vorname": "Kühne Bernadette",
    "Firma": "Swisscom (Schweiz) AG",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 105,
    "Name Vorname": "Kühner Nora",
    "Firma": "Nora Kühner fashion design consulting",
    "Sex": "f",
    "Nationalität": "DE",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 106,
    "Name Vorname": "Kuyda Eugenia",
    "Firma": "Luka",
    "Sex": "f",
    "Nationalität": "US",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 107,
    "Name Vorname": "Landwehr Dominik",
    "Firma": "Migros-Genossenschafts-Bund",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 108,
    "Name Vorname": "Lauria Giuliana",
    "Firma": "Kompetenzzentrum für Lebensqualität Schönbühl",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 109,
    "Name Vorname": "Le Flocq Claudia",
    "Firma": "dialogköpfe gmbh",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 110,
    "Name Vorname": "Lehmann Tobias",
    "Firma": "SIX Group AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 111,
    "Name Vorname": "Lemaire Fabienne",
    "Firma": "Liechtensteinische Kraftwerke",
    "Sex": "f",
    "Nationalität": "LI",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 112,
    "Name Vorname": "Leu Peter",
    "Firma": "",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 113,
    "Name Vorname": "Linden David J.",
    "Firma": "Johns Hopkins University",
    "Sex": "m",
    "Nationalität": "US",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 114,
    "Name Vorname": "Llewellynn Tim",
    "Firma": "NVISO SA EPFL Innovation Park",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 115,
    "Name Vorname": "Löﬄer Lisa-Marie",
    "Firma": "Liegenschaften-Betrieb AG",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 116,
    "Name Vorname": "Loos Melanie",
    "Firma": "Handelszeitung",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 117,
    "Name Vorname": "Löpfe Philipp",
    "Firma": "Watson",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 118,
    "Name Vorname": "Lüscher Matthias",
    "Firma": "SUISSEDIGITAL Verband für Kommunikationsnetze",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 119,
    "Name Vorname": "Maas Peter",
    "Firma": "Universität St. Gallen",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 120,
    "Name Vorname": "Manacchini Mauro",
    "Firma": "Coop Genossenschaft Schweiz",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 121,
    "Name Vorname": "Mani Rolf",
    "Firma": "Schweizerische Bundesbahnen SBB",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 122,
    "Name Vorname": "Marfurt Thomas",
    "Firma": "Swisscom (Schweiz) AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 123,
    "Name Vorname": "Märkli Vera",
    "Firma": "Gemeinde Vaduz",
    "Sex": "f",
    "Nationalität": "LI",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 124,
    "Name Vorname": "Marti Michael",
    "Firma": "Tages-Anzeiger",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 125,
    "Name Vorname": "Martin Andreas",
    "Firma": "HR eﬃzienz GmbH",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 126,
    "Name Vorname": "Meier Marc",
    "Firma": "Avectris AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 127,
    "Name Vorname": "Metternich Nils",
    "Firma": "Scholz & Volkmer GmbH",
    "Sex": "m",
    "Nationalität": "DE",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 128,
    "Name Vorname": "Metzler Marco",
    "Firma": "NZZ am Sonntag",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 129,
    "Name Vorname": "Meyer Martin",
    "Firma": "UBS Switzerland AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 130,
    "Name Vorname": "Millán José del R.",
    "Firma": "Ecole Polytechnique Fédérale de Lausanne (EPFL)",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 131,
    "Name Vorname": "Mühlethaler Kurt",
    "Firma": "Migros-Genossenschafts-Bund",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 132,
    "Name Vorname": "Nave Michael",
    "Firma": "Bottimmo AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 133,
    "Name Vorname": "Nussbaumer Markus",
    "Firma": "SCS Storeconcept AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 134,
    "Name Vorname": "Obi Regula",
    "Firma": "intosens ag urban solutions",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 135,
    "Name Vorname": "Ospelt Philipp",
    "Firma": "Herbert Ospelt Anstalt",
    "Sex": "m",
    "Nationalität": "LI",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 136,
    "Name Vorname": "Österlund Jowan",
    "Firma": "BioHax International",
    "Sex": "m",
    "Nationalität": "SE",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 137,
    "Name Vorname": "Perini Martin",
    "Firma": "KOMMPAKT AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 138,
    "Name Vorname": "Perot Roberto",
    "Firma": "",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 139,
    "Name Vorname": "Pfenninger Jan",
    "Firma": "Planzer Support AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 140,
    "Name Vorname": "Plock-Girmann Friederike",
    "Firma": "Volkswagen AG",
    "Sex": "f",
    "Nationalität": "DE",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 141,
    "Name Vorname": "Poddar Nadia",
    "Firma": "Jaduna Advisory GmbH",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 142,
    "Name Vorname": "Pohl Sven",
    "Firma": "Milupa - Danone Nutricia",
    "Sex": "m",
    "Nationalität": "DE",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 143,
    "Name Vorname": "Preisinger Wolfgang",
    "Firma": "Die Fabrikanten",
    "Sex": "m",
    "Nationalität": "AT",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 144,
    "Name Vorname": "Prenosil Tomas",
    "Firma": "Confiserie Sprüngli AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 145,
    "Name Vorname": "Przegalinska Aleksandra",
    "Firma": "Kozminski University",
    "Sex": "f",
    "Nationalität": "PL",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 146,
    "Name Vorname": "Rajki Florian",
    "Firma": "Universitätsspital Zürich",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 147,
    "Name Vorname": "Rauh Markus",
    "Firma": "Beratung für Strategie",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 148,
    "Name Vorname": "Reimann Melanie",
    "Firma": "Coop Genossenschaft Schweiz",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 149,
    "Name Vorname": "Reimer Ricarda T.D.",
    "Firma": "Fachhochschule Nordwestschweiz FHNW",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 150,
    "Name Vorname": "Reinert Nadine",
    "Firma": "Philosophicum",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 151,
    "Name Vorname": "Rennefahrt Lisa",
    "Firma": "Liegenschaften-Betrieb AG",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 152,
    "Name Vorname": "Reuter Hans Jakob",
    "Firma": "Gicom GmbH",
    "Sex": "m",
    "Nationalität": "DE",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 153,
    "Name Vorname": "Rhomberg Christine",
    "Firma": "Kontiki Reisen DER Touristik Suisse AG",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 154,
    "Name Vorname": "Robinson Thomas",
    "Firma": "",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 155,
    "Name Vorname": "Roetz Carsten",
    "Firma": "Swisscom (Schweiz) AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 156,
    "Name Vorname": "Rojas Diana",
    "Firma": "",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 157,
    "Name Vorname": "Roost Thomas",
    "Firma": "Hapimag AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 158,
    "Name Vorname": "Rosendorf Ruth",
    "Firma": "Österreich Werbung",
    "Sex": "f",
    "Nationalität": "AT",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 159,
    "Name Vorname": "Rossnagel Klemens",
    "Firma": "Audi China Enterprise Management Co., Ltd.",
    "Sex": "m",
    "Nationalität": "CN",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 160,
    "Name Vorname": "Roth Hans",
    "Firma": "Hans Roth Unternehmensberatung GmbH",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 161,
    "Name Vorname": "Rutschi Corinna",
    "Firma": "University of Bern",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 162,
    "Name Vorname": "Rutz Deborah",
    "Firma": "Micarna SA",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 163,
    "Name Vorname": "Santschi Michèle",
    "Firma": "Universitätsspital Zürich",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 164,
    "Name Vorname": "Sawodny Rut",
    "Firma": "Volkswagen AG",
    "Sex": "f",
    "Nationalität": "DE",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 165,
    "Name Vorname": "Schäfer Christine",
    "Firma": "GDI Gottlieb Duttweiler Institute",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 166,
    "Name Vorname": "Schär Bellwald Sandra",
    "Firma": "USM Modular Furniture USM U. Schärer Söhne AG",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 167,
    "Name Vorname": "Schatzmann Rolf",
    "Firma": "SAP (Schweiz) AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 168,
    "Name Vorname": "Schauﬀert Thomas",
    "Firma": "THS sounds words and life Consulting - Development - Action",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 169,
    "Name Vorname": "Schehrer Dierk",
    "Firma": "TCS Schweiz",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 170,
    "Name Vorname": "Schenk Barbara",
    "Firma": "HOGAST Einkaufsgenossenschaft f. d. Hotel- und Gastgewerbe regGenmbH AT",
    "Sex": "f",
    "Nationalität": "AT",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 171,
    "Name Vorname": "Scheppach Joseph",
    "Firma": "",
    "Sex": "m",
    "Nationalität": "DE",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 172,
    "Name Vorname": "Schiller Matthias",
    "Firma": "Schindler Digital Group AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 173,
    "Name Vorname": "Schmid Ingrid",
    "Firma": "GDI Gottlieb Duttweiler Institute",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 174,
    "Name Vorname": "Schmid Katrin",
    "Firma": "Schweizer Radio und Fernsehen SRF",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 175,
    "Name Vorname": "Schmid Peter",
    "Firma": "schmidpm",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 176,
    "Name Vorname": "Schmid Roger",
    "Firma": "Raiﬀeisen Schweiz Genossenschaft",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 177,
    "Name Vorname": "Schön Alexandra",
    "Firma": "Gigaset Communications GmbH",
    "Sex": "f",
    "Nationalität": "DE",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 178,
    "Name Vorname": "Schué Stephan",
    "Firma": "SV (Schweiz) AG Seminarhotel Bocken",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 179,
    "Name Vorname": "Schürch Michel",
    "Firma": "SCS Storeconcept AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 180,
    "Name Vorname": "Schürmann Rolf",
    "Firma": "IV-Stelle Basel-Stadt",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 181,
    "Name Vorname": "Schwarz Cyril",
    "Firma": "Max Schwarz AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 182,
    "Name Vorname": "Schwarz-Zurkinden Max",
    "Firma": "Max Schwarz AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 183,
    "Name Vorname": "Schweizer Christian",
    "Firma": "Geschäftsstelle sozialinfo.ch",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 184,
    "Name Vorname": "Seiler Panagiota",
    "Firma": "Sparda-Bank Baden Württemberg eG",
    "Sex": "f",
    "Nationalität": "DE",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 185,
    "Name Vorname": "Seinige Tom",
    "Firma": "tbd. - On-/Oﬄine Werbung",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 186,
    "Name Vorname": "Sgier de Cerf Charlotte",
    "Firma": "Bundesamt für Kommunikation BAKOM",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 187,
    "Name Vorname": "Sidler Sarah",
    "Firma": "Hotel & Gastro Union",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 188,
    "Name Vorname": "Sieber Mona",
    "Firma": "",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 189,
    "Name Vorname": "Siegfried Stephan",
    "Firma": "1-prozent GmbH",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 190,
    "Name Vorname": "Sollberger Caroline",
    "Firma": "Mibelle Group Mibelle AG",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 191,
    "Name Vorname": "Stegmaier Thomas",
    "Firma": "Rapp Architekten AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 192,
    "Name Vorname": "Steiner Marc",
    "Firma": "BELIMO Holding AG Belimo Automation AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 193,
    "Name Vorname": "Steiner Petra",
    "Firma": "PS Design Visuelle Kommunikation",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 194,
    "Name Vorname": "Steinmann Luca",
    "Firma": "Schweizerische Bundesbahnen SBB",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 195,
    "Name Vorname": "Steinmetz Remo",
    "Firma": "Swiss Re Management Ltd.",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 196,
    "Name Vorname": "Stiefel Sarah",
    "Firma": "Schweizerische Bundesbahnen SBB",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 197,
    "Name Vorname": "Stifter Eleonore Marie",
    "Firma": "",
    "Sex": "f",
    "Nationalität": "GB",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 198,
    "Name Vorname": "Straube Christian",
    "Firma": "Zühlke Engineering AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 199,
    "Name Vorname": "Strik Karin",
    "Firma": "HUG AG",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 200,
    "Name Vorname": "Studer Christian",
    "Firma": "Insel Gruppe AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 201,
    "Name Vorname": "Stumm Martina",
    "Firma": "F. Hoﬀmann-La Roche AG",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 202,
    "Name Vorname": "Thalmann Leonie",
    "Firma": "",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 203,
    "Name Vorname": "Thalmann Lioudmila",
    "Firma": "InnoPark Schweiz AG",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 204,
    "Name Vorname": "Theler Luc",
    "Firma": "Hapimag AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 205,
    "Name Vorname": "Thiel Juliane",
    "Firma": "Autostadt GmbH",
    "Sex": "f",
    "Nationalität": "DE",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 206,
    "Name Vorname": "Thorner Julian",
    "Firma": "Andreas & Conrad AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 207,
    "Name Vorname": "Trachte Karolin",
    "Firma": "Schauspielhaus Zürich AG",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 208,
    "Name Vorname": "Treml Christine",
    "Firma": "SIX Group AG",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 209,
    "Name Vorname": "Tschugmall Melanie",
    "Firma": "Zühlke Engineering AG",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 210,
    "Name Vorname": "Urscheler Pascal",
    "Firma": "Zühlke Engineering AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "12.Nov.73",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 211,
    "Name Vorname": "van de Sand Felix",
    "Firma": "Cobe GmbH",
    "Sex": "m",
    "Nationalität": "DE",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 212,
    "Name Vorname": "Vogt Reto",
    "Firma": "Migros-Genossenschafts-Bund Migros Medien",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 213,
    "Name Vorname": "Waldburger Paul",
    "Firma": "Rapp Architekten AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 214,
    "Name Vorname": "Walde Gerhard E.",
    "Firma": "Walde & Partner Immobilien AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 215,
    "Name Vorname": "Weber Jürg",
    "Firma": "Censhare (Schweiz) AG",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 216,
    "Name Vorname": "Wegmann Rudolf",
    "Firma": "",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 217,
    "Name Vorname": "Wegmann Werner",
    "Firma": "",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 218,
    "Name Vorname": "Wehrli Angela",
    "Firma": "Mibelle Group Mibelle AG",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 219,
    "Name Vorname": "Weiss Frank",
    "Firma": "Oracle Aconex AG",
    "Sex": "m",
    "Nationalität": "DE",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 220,
    "Name Vorname": "Werner Christoph",
    "Firma": "dm-drogerie markt GmbH + Co. KG",
    "Sex": "m",
    "Nationalität": "DE",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 221,
    "Name Vorname": "Widmer Annemarie",
    "Firma": "Louis Widmer SA",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 222,
    "Name Vorname": "Wilke Lilian Susann",
    "Firma": "Wort und Bildverlag Konradshöhe GmbH & Co. KG",
    "Sex": "f",
    "Nationalität": "DE",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 223,
    "Name Vorname": "Winter Thomas",
    "Firma": "Stadtwerke Winterthur",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 224,
    "Name Vorname": "Witt Christian",
    "Firma": "",
    "Sex": "m",
    "Nationalität": "DE",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 225,
    "Name Vorname": "Wruck Christian",
    "Firma": "",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 226,
    "Name Vorname": "Wu Tim",
    "Firma": "Columbia Law School",
    "Sex": "m",
    "Nationalität": "US",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 227,
    "Name Vorname": "Wurz-Janssens Regina",
    "Firma": "Feldschlösschen Getränke AG",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 228,
    "Name Vorname": "Yaacoub Jana",
    "Firma": "Rosenstar GmbH Agentur für Werte & Design",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 229,
    "Name Vorname": "Yurkova Olga",
    "Firma": "StopFake.org",
    "Sex": "f",
    "Nationalität": "",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 230,
    "Name Vorname": "Zemp Franz",
    "Firma": "Universitätsspital Zürich",
    "Sex": "m",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 231,
    "Name Vorname": "Zimmermann Therese E.",
    "Firma": "Universität Bern",
    "Sex": "f",
    "Nationalität": "CH",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 232,
    "Name Vorname": "Zink Peter",
    "Firma": "Audi AG",
    "Sex": "m",
    "Nationalität": "DE",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  },
  {
    "id": 233,
    "Name Vorname": "Zuber Jörg",
    "Firma": "Opium eﬀect GmbH",
    "Sex": "m",
    "Nationalität": "DE",
    "Linkedin": "",
    "FIELD7": "",
    "FIELD8": ""
  }
 ];