import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { GridComponent } from './grid.component';
import { AppComponent } from './app.component';

const routes: Routes = [
  { path: '', component: GridComponent },
  { path: ':ids', component: GridComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    GridComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(
      routes
    )
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
