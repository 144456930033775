import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { data } from './attendants';
import { ActivatedRoute, ParamMap } from '@angular/router';

interface DataObject {
  id: number | string,
  selected: boolean,
}

@Component({
  selector: 'grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit {

  @ViewChild('container') container: ElementRef;

  items: object[];
  root: any;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.root = document.documentElement;

    this.items = data;
    this.setRectSize(window.innerWidth, window.innerHeight);

    this.route.queryParams.subscribe(obj => {
      if ( obj !== null && obj.ids !== null) {
        const ids: string[] = obj.ids.split(',');

        this.items.map((i: DataObject) => {
          if (typeof ids.find(id => id == i.id) !== 'undefined') {
            i.selected = true;
          }
        });

      }
    });

    // this.route.paramMap.subscribe((paramMap: ParamMap) => {

    //   if (paramMap.get('ids') !== null) {
    //     const ids: string[] = paramMap.get('ids').split(',');

    //     this.items.map((i: DataObject) => {
    //       if (typeof ids.find(id => id == i.id) !== 'undefined') {
    //         i.selected = true;
    //       }
    //     });

    //   }

    // });

  }

  image(id) {
    return `url(/assets/users/${id}.jpg)`;
    // return `url(${url})`;
  }

  calc(x, y, v) {

    let px = Math.ceil(Math.sqrt(v * x / y));
    let sx, sy;

    if (Math.floor(px * y / x) * px < v) {
      sx = y / Math.ceil(px * y / x);
    } else {
      sx = x / px;
    }

    let py = Math.ceil(Math.sqrt(v * y / x));

    if (Math.floor(py * x / y) * py < v) {
      sy = x / Math.ceil(x * py / y);
    } else {
      sy = y / py;
    }

    function max(x, y) {
      return x > y ? x : y;
    }

    console.log(max(sx, sy), max(sx, sy));
    // 10px come from grid-gap (padding)
    return max(sx, sy);

  }

  setRectSize(x: number, y: number): void {
    this.root.style.setProperty('--rect', this.calc(x, y, this.items.length) + "px");
  }

  onResize(e) {
    this.setRectSize(e.target.innerWidth, e.target.innerHeight)
  }

}
